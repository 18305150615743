




































































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { ExamTime, PageNum, School } from "@/tool/interface-index";
import { ExamProject } from "@/tool/interface-index";
import * as _ from "lodash";
import * as CommentApi from "@/api/examComment";
import * as ExamPrjApi from "@/api/examProject";
import {
  CommentParams,
  ExamSchoolsStatisticsReport,
  ExamSchoolStatistics,
} from "@/classes/exam-comment";
import { getSchoolCascadeList } from "@/api/school";
import { getExamProjectList } from "@/api/examProject";
import * as ResApi from "@/api/examFileResource";
import dayjs from "dayjs";
import { exportJson2Excel } from "@/utils/excel";
import { formatJson } from "@/utils";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

@Component({
  name: "FormalExamSchoolListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };

  private items: any[] = [];
  private examProjectList: ExamProject[] = [];
  private examTimeGroupList: { value: string; label: string }[] = [];

  private query: {
    keyWords: string;
    cascadeExamSchoolId: string[];
    examProjectId: string;
    //examStartTimeWithinDays: number;
  } = {
    cascadeExamSchoolId: [],
    keyWords: "",
    examProjectId: "",
    //examStartTimeWithinDays: 90,
  };

  private schools: any[] = [];

  private loading: boolean = false;
  private examSchoolsStatistics: ExamSchoolsStatisticsReport | undefined =
    undefined;

  private get examType(): string {
    return "formalExamMode";
    const paths = this.$route.path.split("/");
    const isMockExamMode =
      paths.findIndex((value: string) => {
        return value.toLowerCase() == "mockExamManagement".toLowerCase();
      }) >= 0;
    if (isMockExamMode) {
      return "mockExamMode";
    }
    return "formalExamMode";
  }

  private lastExamStateTimeFormatter(row: any, column: any) {
    if (dayjs(row.lastExamStateTime).isValid()) {
      return dayjs(row.lastExamStateTime).format("HH:mm:ss");
    }
    return "无";
  }

  private async getExamSchoolListData(displayLoading: boolean) {
    try {
      if (displayLoading) {
        this.loading = true;
      }
      const params = {
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage,
        keyWords: this.query.keyWords,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
        examProjectId: this.query.examProjectId,
        examType: this.examType,
        //examStartTimeWithinDays: this.query.examStartTimeWithinDays,
      };
      const { data } = await CommentApi.getExamSchoolsReport(params);
      this.items.splice(0, this.items.length);
      this.pageNum.totalPage = data.totalPage;
      this.examSchoolsStatistics = data;
      //删除旧数据
      /*
      this.items
        .filter((item) => {
          return !this.examSchoolsStatistics!.items.some(
            (item1) =>
              item.examProjectId == item1.examProjectId &&
              item.examSchoolId == item1.examSchoolId
          );
        })
        .forEach((item) => {
          const idx = this.items.findIndex((item1) => {
            return (
              item.examProjectId == item1.examProjectId &&
              item.examSchoolId == item1.examSchoolId
            );
          });
          if (idx >= 0) {
            this.items.splice(idx, 1);
          }
        });
      */
      //添加及更新数据
      this.examSchoolsStatistics!.items.forEach(
        (item: ExamSchoolStatistics) => {
          const item1 = this.items.find((item1) => {
            return (
              item.examProjectId == item1.examProjectId &&
              item.examSchoolId == item1.examSchoolId
            );
          });
          if (item1) {
            _.merge(item1, item);
          } else {
            this.items.push(item);
          }
        }
      );
      /*
      _.mapKeys(params, (value, key)=>{

      })
      
      this.$route.query.push('', '');// = params;
    */

      this.$nextTick(() => {
        const tableView = this.$refs.examAutoScoreTableView as any;
        if (tableView) {
          tableView.doLayout();
        }
      });
    } finally {
      if (displayLoading) {
        this.loading = false;
      }
    }
  }

  private upDataPage() {
    this.getExamSchoolListData(true);
  }

  private queryAutoScoresClick() {
    this.pageNum.curPage = 1;
    this.getExamSchoolListData(true);
  }

  async doReScoringAllClick() {
    await this.$confirm(`重评当前所有统计结果, 是否继续?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    for (let i = 0; i < this.items.length; i++) {
      let item: ExamSchoolStatistics = this.items[i];
      //this.doReScoringExamStudentsClick(item);
      this.scoreExamTime(item, "" /*this.recipients*/);
    }
  }

  async doDownloadAllClick() {
    for (let i = 0; i < this.items.length; i++) {
      let item: ExamSchoolStatistics = this.items[i];
      await this.handleDownloadExamStatisticsReportClick(item);
    }
  }

  async handleDownloadExamStatisticsReportClick(row: ExamSchoolStatistics) {
    /*
    try {
      const fileName = `${row.examProjectName}(${row.examSchoolName}-${row.examTimeName})考生成绩明细[(不含缺考)].xlsx`;
      const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${row.examProjectId}&examTimeId=${row.examTimeId}&fileName=${fileName}`;

      ResApi.download(`${examStudentsReportShortFileUrl}`, {}, fileName);
    } catch (error) {
      //
    }
    */
  }

  private tableDataToExcel(tHeader: string[], data: any[], fileName: string) {}

  private exportTableData(items: any[]) {
    const titles: { key: string; title: string }[] = [
      { key: "examSchoolName", title: "学校" },
      { key: "examProjectName", title: "项目" },
      { key: "examStudentCount", title: "报考" },
      { key: "minExamAccount", title: "最小考号" },
      { key: "maxExamAccount", title: "最大考号" },
      { key: "multiSchool", title: "多校" },
      { key: "examNull", title: "缺考" },
      { key: "examNone", title: "重置" },
      { key: "examHas", title: "未考完" },
      { key: "examEnded", title: "已考完" },
      { key: "pkgCount", title: "已考完数据包" },
      { key: "lastExamStateTime", title: "新状态时间" },
    ];

    const filterVal: string[] = titles.map((item) => item.key);
    const data: any[] = formatJson(filterVal, items);
    const tHeader: string[] = titles.map((item) => item.title);
    exportJson2Excel(tHeader, data, `考点统计`);
  }

  private loadingExport: boolean = false;
  private doExportClick() {
    try {
      this.loadingExport = true;
      this.exportTableData(this.items);
    } finally {
      this.loadingExport = false;
    }
  }

  private async exportExamEnds(row: ExamSchoolStatistics): Promise<any[]> {
    const { data } = await CommentApi.getExamSchoolStatistics(
      row.examProjectId,
      row.examSchoolId
    );
    let examNulls: any[] = _.get(data, "examStudents", []).filter((v: any) => {
      let result1 = v!.statisticsTag.toLowerCase() == "examNull".toLowerCase();
      const tag = v!.statisticsTag.toLowerCase();
      const tag1 = "examNone".toLowerCase();
      const tag2 = "examHas".toLowerCase();
      let result2 = (tag == tag1 || tag == tag2) && !v.examAnswerPackageFileUrl;
      return result1 || result2;
    });

    const filterVal = [
      "examSchoolName",
      "schoolName",
      "className",
      "examAccount",
      "examStudentName",
      "statisticsTag",
    ];
    const data1: any[] = formatJson(
      filterVal,
      examNulls.map((item) => {
        let statisticsTag: string = "";
        switch (item.statisticsTag.toLowerCase()) {
          case "examNull".toLowerCase(): {
            statisticsTag = "缺考";
            break;
          }
          case "examNone".toLowerCase(): {
            statisticsTag = "已重置";
            break;
          }
          case "examHas".toLowerCase(): {
            statisticsTag = "未考完";
            break;
          }
          default: {
            statisticsTag = item.statisticsTag;
          }
        }
        return _.merge(item, {
          examSchoolName: row.examSchoolName,
          statisticsTag,
        });
      })
    );
    this.examNullsToExcel(
      data1,
      `${_.get(data, "examProjectName", "")}_${_.get(
        data,
        "examSchoolName",
        ""
      )}_缺考_名单`
    );
    return data1;
  }

  private loadingExportAllExamEnds: boolean = false;
  private doExportAllExamEndsClick() {
    try {
      this.loadingExportAllExamEnds = true;
      this.items.forEach(async (item) => {
        await this.exportExamEnds(item);
      });
    } finally {
      this.loadingExportAllExamEnds = false;
    }
  }

  private async exportExamFaileds(row: ExamSchoolStatistics) {
    const { data } = await CommentApi.getExamSchoolStatistics(
      row.examProjectId,
      row.examSchoolId
    );
    let examFaileds: any[] = _.get(data, "examStudents", []).filter(
      (v: any) => {
        const tag = v!.statisticsTag.toLowerCase();
        const tag1 = "examNone".toLowerCase();
        const tag2 = "examHas".toLowerCase();
        return tag == tag1 || tag == tag2;
      }
    );
    for (let i = 0; i < examFaileds.length; i++) {
      console.log(JSON.stringify(examFaileds[i]));
      let examAnswerPackageDetailFileNames =
        examFaileds[i]?.examAnswerPackageDetailFileNames || [];
      let answerFileCount = examAnswerPackageDetailFileNames.filter(
        (val: string) => {
          return val != "test.mp3";
        }
      ).length;
      let hasTestFile = examAnswerPackageDetailFileNames.some((val: string) => {
        return val == "test.mp3";
      });
      _.merge(examFaileds[i], {
        hasTestFile: hasTestFile ? "有" : "无",
        answerFileCount,
        mark: "深圳：中学14个录音、小学8个录音可视为数据完整",
      });
    }
    /*
      console.log(JSON.stringify(examFaileds));
      examFaileds = examFaileds.map((item: any) => {
        
        let answerFileCount = item.examAnswerPackageDetailFileNames.filter(
          (val: string) => {
            return val != "test.mp3";
          }
        ).length;
        let hasTestFile: boolean = item.examAnswerPackageDetailFileNames.some(
          (val: string) => {
            return val == "test.mp3";
          }
        );
        return _.merge(item, {
          hasTestFile,
          answerFileCount,
          mark: "深圳：中学14个录音、小学8个录音可视为数据完整",
        });
      });
*/
    const tHeader = [
      "班级",
      "考号",
      "姓名",
      "状态",
      "数据包",
      "作答录音数",
      "测音文件",
      "备注",
    ];
    const filterVal = [
      "className",
      "examAccount",
      "examStudentName",
      "statisticsTag",
      "examAnswerPackageFileUrl",
      "answerFileCount",
      "hasTestFile",
      "mark",
    ];
    const data1 = formatJson(filterVal, examFaileds);
    exportJson2Excel(
      tHeader,
      data1,
      `${_.get(row, "examProjectName", "")}_${_.get(
        row,
        "examSchoolName",
        ""
      )}_未结束考试名单`
    );
  }

  private loadingExportAllExamFaileds: boolean = false;
  private doExportAllExamFailedsClick() {
    try {
      this.loadingExportAllExamFaileds = true;
      this.items.forEach(async (item) => {
        await this.exportExamFaileds(item);
      });
    } finally {
      this.loadingExportAllExamFaileds = false;
    }
  }

  private async handleExportCommand(
    command: string,
    row: ExamSchoolStatistics
  ) {
    switch (command.toLocaleLowerCase()) {
      case "ExportExamFaileds".toLocaleLowerCase(): {
        await this.exportExamFaileds(row);
        break;
      }
      case "ExportExamNulls".toLocaleLowerCase(): {
        await this.exportExamNulls(row);
        break;
      }
    }
  }

  private async doExportExamFailedsClick(row: ExamSchoolStatistics) {
    await this.exportExamFaileds(row);
  }

  private totalExamNulls: any[] = [];
  private loadingExportAllExamNulls: boolean = false;
  private async doExportAllExamNullsClick() {
    try {
      this.loadingExportAllExamNulls = true;
      this.totalExamNulls.splice(0, this.totalExamNulls.length);
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        const data = await this.exportExamNulls(item);
        this.totalExamNulls.push(...data);
      }
      this.examNullsToExcel(
        this.totalExamNulls,
        `${_.get(this.items[0], "examProjectName", "")}_00总表_缺考_名单`
      );
    } finally {
      this.loadingExportAllExamNulls = false;
    }
  }

  private async exportExamNulls(row: ExamSchoolStatistics): Promise<any[]> {
    const { data } = await CommentApi.getExamSchoolStatistics(
      row.examProjectId,
      row.examSchoolId
    );
    /**
     * 未考或没有作答记录的视为缺考
     * 按理如有参加考试，应该保证答答完整；所以此处只显示未考或没有作答记录的考生
     */
    let examNulls: any[] = _.get(data, "examStudents", []).filter((v: any) => {
      let result1 = v!.statisticsTag.toLowerCase() == "examNull".toLowerCase();
      const tag = v!.statisticsTag.toLowerCase();
      const tag1 = "examNone".toLowerCase();
      const tag2 = "examHas".toLowerCase();
      let result2 = (tag == tag1 || tag == tag2) && !v.examAnswerPackageFileUrl;
      return result1 || result2;
    });

    const filterVal = [
      "examSchoolName",
      "schoolName",
      "className",
      "examAccount",
      "examStudentName",
      "sex",
      "photoFileName",
      "statisticsTag",
    ];
    const data1: any[] = formatJson(
      filterVal,
      examNulls.map((item) => {
        let statisticsTag: string = "";
        switch (item.statisticsTag.toLowerCase()) {
          case "examNull".toLowerCase(): {
            statisticsTag = "缺考";
            break;
          }
          case "examNone".toLowerCase(): {
            statisticsTag = "已重置(无作答记录)";
            break;
          }
          case "examHas".toLowerCase(): {
            statisticsTag = "未考完(无作答记录)";
            break;
          }
          default: {
            statisticsTag = item.statisticsTag;
          }
        }
        let photoFileName: string = "无";
        if (!item.sex) {
          item.sex = "无";
        }
        return _.merge(item, {
          examSchoolName: row.examSchoolName,
          statisticsTag,
          photoFileName,
        });
      })
    );
    this.examNullsToExcel(
      data1,
      `${_.get(data, "examProjectName", "")}_${_.get(
        data,
        "examSchoolName",
        ""
      )}_缺考_名单`
    );
    return data1;
  }

  private examNullsToExcel(data: any[], fileName: string) {
    const tHeader = [
      "考点名称",
      "学校",
      "班级",
      "考号",
      "姓名",
      "性别",
      "相片",
      "状态",
    ];
    exportJson2Excel(tHeader, data, fileName);
  }

  private async doExportExamNullsClick(row: ExamSchoolStatistics) {
    await this.exportExamNulls(row);
  }

  doShowDetailsClick(row: ExamSchoolStatistics) {
    this.$router.push({
      path: "examSchoolStatisticsDetails",
      query: {
        examProjectId: row.examProjectId,
        examSchoolId: row.examSchoolId,
        examType: "formalExamMode",
      },
    });
  }

  doRouteToStudentsScoreListClick(row: ExamSchoolStatistics) {
    /*
    this.$router.push({
      path: "examStudentScoreList",
      query: {
        examProjectId: row.examProjectId,
        examTimeId: row.examTimeId,
        examSchoolId: row.examSchoolId,
        examType: "formalExamMode"
      }
    });
    */
  }

  private loadingScore: boolean = false;
  async scoreExamTime(examTime: ExamSchoolStatistics, recipients: string) {
    try {
      this.loadingScore = true;
      //
      /*
      await ExamPrjApi.postScoreExamProject({
        examProjectId: examTime.examProjectId,
        examSchoolId: examTime.examSchoolId,
        examTimeId: examTime.examTimeId,
        recipients: recipients
      });
      */
    } finally {
      this.loadingScore = false;
    } //try 0
  }

  private loadingScoreExamAnswerPackage: boolean = false;
  async doReScoringExamStudentsClick(row: ExamSchoolStatistics) {
    /*
    //参数判断
    if (row.examProjectId == "" || row.examTimeId == "") {
      this.$message({
        message: '请先选择及搜索：考试项目及场次。',
        type: "warning",
        duration: 5 * 1000, // 100000
        offset: 60
      });
      return;
    };

    await this.$confirm(`重评当前统计结果, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    });
    try {
      this.loadingScoreExamAnswerPackage = true;
      //2021/09/08测评不需要邮件与发邮件功能分离
      //await this.promptRecipients();
      //
      await this.scoreExamTime(row, "");//this.recipients


      this.$message({
        message: '测评完成。',
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60
      });
    } finally {
      this.loadingScoreExamAnswerPackage = false;
    }
    */
  }

  private recipients: string = "";
  async promptRecipients() {
    const promptData: any = await this.$prompt(
      "请输入接收测评结果的邮箱",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: this.recipients,
        inputPattern:
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "邮箱格式不正确",
      }
    );
    this.recipients = promptData.value;
  }

  private loadingSendEmail: boolean = false;

  private async doSendExamStatisticsReportClick(row: ExamSchoolStatistics) {
    /*
    //参数判断
    if (row.examProjectId == "" || (row.examSchoolId == "")) {
      this.$message({
        message: '参数错误：项目、学校不能为空。',
        type: "warning",
        duration: 5 * 1000, // 100000
        offset: 60
      });
      return;
    };
    //
    await this.promptRecipients();
    //
    try {
      this.loadingSendEmail = true;
      await CommentApi.sendExamStatisticsReport({
        examProjectId: row.examProjectId,
        examSchoolId: row.examSchoolId,
        examTimeId: row.examTimeId,
        recipients: this.recipients
      });
      this.$message({
        message: `邮件已发送至：${this.recipients}。`,
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60
      });
    } finally {
      this.loadingSendEmail = false;
    }
    */
  }

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
    /*
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        return cascades[i]
      }
      if (_.has(cascades[i], 'children')) {
        return this.getCascadeItem(keyId, cascades[i].children);
      }

    }
    */
  }

  private getCascadeSchoolId(schoolId: string): string[] {
    let arr: string[] = [];
    let keyId: string = schoolId;
    do {
      const item = this.getCascadeItem(keyId, this.schools);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  handleChangeCascadeExamSchool(cascadeExamSchoolId: any) {
    this.__init_examProjectList();
  }

  private async __init_examProjectList() {
    try {
      this.examProjectList.splice(0, this.examProjectList.length);
      this.examTimeGroupList.splice(0, this.examTimeGroupList.length);
      const res = await getExamProjectList({
        examType: this.examType,
        pageSize: 100,
        curPage: 1,
        //examStartTimeWithinDays: this.query.examStartTimeWithinDays,
        examSchoolId: _.last(this.query.cascadeExamSchoolId) || "",
      });

      this.examTimeGroupList = _.uniq(
        res.data.items.map((item: any) => item.examEndTimeStr)
      )
        .sort((a: any, b: any) => {
          if (a < b) {
            return 1;
          } else if (a > b) {
            return -1;
          }
          return 0;
        })
        .map((item: any) => {
          return {
            value: item,
            label: item,
          };
        });

      this.examProjectList = res.data.items.map((item: any) => {
        return _.merge(item, {
          value: item.examProjectId,
          label: item.examProjectName,
        });
      });
    } finally {
      const examProject = this.examProjectList.find((item) => {
        return item.examProjectId == this.query.examProjectId;
      });
      if (!examProject) {
        this.query.examProjectId = "";
      }
    }
  }

  private __init() {
    getSchoolCascadeList().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });
    this.__init_examProjectList();
  }

  private busy(): boolean {
    return this.loadingExport || this.loadingExportAllExamEnds || this.loadingExportAllExamFaileds || this.loadingExportAllExamNulls;
  }

  private autoRefreshInterval: NodeJS.Timeout | undefined = undefined;
  mounted() {
    this.__init();
    //
    this.getExamSchoolListData(true);
    clearInterval(this.autoRefreshInterval!);
    this.autoRefreshInterval = setInterval(() => {
      if (this.busy()) {
        return;
      }
      this.getExamSchoolListData(false);
    }, 30 * 1000);
  }

  activated() {
    this.getExamSchoolListData(true);
    clearInterval(this.autoRefreshInterval!);
    this.autoRefreshInterval = setInterval(() => {
      if (this.busy()) {
        return;
      }
      this.getExamSchoolListData(false);
    }, 30 * 1000);
  }

  deactivated() {
    clearInterval(this.autoRefreshInterval!);
  }

  beforeRouteEnter(to: any, from: any, next: any) {
    try {
      const s1 = ((_.last(to.path.split("/")) as string) || "").toLowerCase();
      const s2 = ((_.last(from.path.split("/")) as string) || "").toLowerCase();
      console.log(
        `beforeRouteEnter, ${to.path} ~ ${s1} <--> ${from.path} ${s2}`
      );
      if (s1 == s2 && to.path != from.path) {
        console.log(`beforeRouteEnter, true, `);

        /*
        setTimeout(() => {
          this.getExamSchoolListData(true);
        }, 1000);
        */
      }
    } finally {
      next();
    }
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    try {
      const s1 = ((_.last(to.path.split("/")) as string) || "").toLowerCase();
      const s2 = ((_.last(from.path.split("/")) as string) || "").toLowerCase();
      console.log(`beforeRouteUpdate, ${s1}, ${s2}`);
      if (s1 == s2 && to.Path != from!.Path) {
      }
    } finally {
      next();
    }
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    try {
      console.log(`beforeRouteLeave`);

      const s1 = ((_.last(to.path.split("/")) as string) || "").toLowerCase();
      const s2 = ((_.last(from.path.split("/")) as string) || "").toLowerCase();
      const that = this;
      if (s1 == s2) {
        //console.log(`beforeRouteLeave $destroy`);
        //that.$destroy();
      }
    } finally {
      next();
    }
  }
}
