import request from '@/utils/request'
import * as _ from "lodash";
import { CommentParams } from "@/classes/exam-comment";

const MaxTimeout = 150000;

/**
 * 获取本地考场考生列表
 * @param params 
 * @returns 
 */
export const getExamProjectList = (params: {
    curPage: number,
    pageSize: number,
    examProjectId?: string,
    examSchoolId?: string,
    keyWords?: string,
    examState?: string,
    examType?: string,
    examTypes?: string[],
    examStartTimeWithinDays?:number
}) => request({
    url: '/v1/examComment/examProjectList',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 获取讲评考生列表
 * @param params 
 * @returns 
 */
export const getExamStudentList = (params: {
    curPage: number,
    pageSize: number,
    examProjectId: string,
    examSchoolId: string,
    keyWords: string,
    examState: string
}) => request({
    url: '/v1/examComment/examStudentList',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 讲评按项目统计分析报表
 * @param params 
 * @returns 
 */
export const getExamProjectReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examProjectReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 讲评按试题统计分析报表
 * @param params 
 * @returns 
 */
export const getExamQuestionsReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examQuestionsReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 讲评按考生统计分析报表
 * @param params 
 * @returns 
 */
export const getExamStudentsReport = (params: {
    pageSize: number,
    curPage: number,
    examStudentId?: string,
    schoolId?: string,
    examSchoolId?: string,
    examProjectId?: string,
    examTimeId?: string,
    keyWords?: string,
    examType: string,
    examStartTimeWithinDays?: number
}) => request({
    url: '/v1/examComment/statistics/examStudentsReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 讲评按场次统计分析报表
 * @param params 
 * @returns 
 */
export const getExamTimesReport = (params: {
    pageSize: number,
    curPage: number,
    examTimeId?: string,
    schoolId?: string,
    examSchoolId?: string,
    examProjectId?: string,
    keyWords?: string,
    examType: string,
    examStartTimeWithinDays?: number
}) => request({
    url: '/v1/examComment/statistics/examTimesReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 讲评按考场统计分析报表
 * @param params 
 * @returns 
 */
export const getExamSchoolsReport = (params: {
    pageSize: number,
    curPage: number,
    examType: string,
    schoolId?: string,
    examSchoolId?: string,
    examProjectId?: string,
    keyWords?: string,
    examStartTimeWithinDays?: number,
    examStartTimeWithinRangeDays?: number[],
}) => request({
    url: '/v1/examComment/statistics/examSchoolsReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 讲评按考场统计分析报表
 * @param params 
 * @returns 
 */
export const getExamSchoolsByStartDateReport = (params: {
    pageSize: number,
    curPage: number,
    examType: string,
    schoolId?: string,
    examSchoolId?: string,
    examProjectId?: string,
    keyWords?: string,
    examStartTimeWithinDays?: number,
    examStartTimeWithinRangeDays?: number[],
}) => request({
    url: '/v1/examComment/statistics/examSchoolsByStartDateReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 发送分析报告
 * @param data 
 * @param disabledTips 
 * @returns 
 */
export const sendExamStatisticsReport = (data: {
    examProjectId: string,
    examSchoolId: string,
    examTimeId?: string, 
    recipients: string
}, disabledTips: boolean = false) => request({
    url: '/v1/examComment/statistics/sendExamStatisticsReport',
    method: 'post',
    headers: {
        "Content-Type": "application/json",
    },
    data,

    params: {
        disabledTips: disabledTips
    }

});

/**
 * 讲评按考点统计分析报表
 * @param params 
 * @returns 
 */
export const getExamSchoolStatistics = (
    examProjectId: string,
    examSchoolId: string
) => request({
    url: `/v1/examComment/statistics/examSchoolStatistics/${examProjectId}/${examSchoolId}`,
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge({}, {
        //disabledTips: true
    })
});

/**
 * 讲评按考场统计分析报表
 * @param params 
 * @returns 
 */
export const getExamProjectScores = (params: {
    schoolId?: string,
    examSchoolId?: string,
    examProjectId: string,
}) => request({
    url: '/v1/examComment/statistics/examProjectScores',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    }),
    timeout: MaxTimeout
});
